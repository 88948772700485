<template>
  <div class="container-fluid">
	<div class="row">
		<div class="col-lg-12 mb-3">
			<div class="d-flex align-items-center justify-content-between welcome-content">
				<div class="navbar-breadcrumb">
					<h4 class="mb-0 font-weight-700">Selamat Datang, {{ user.fullName }}</h4> 
				</div>
			</div>
		</div>
		<div class="col-lg-12">
			<div class="card card-block card-stretch card-height">
				<div class="card-header d-flex justify-content-between">
					<div class="header-title">
						<h4 class="card-title">Anggaran</h4> 
					</div>
				</div>
				<div class="card-body align-items-center">
					<div id="anggaranChart"></div>
					<!-- <ApexChart element="dash-chart-03" :chartOption="anggaranChart"  /> -->
				</div>
			</div>
			<!-- <div class="row">
				<div class="col-lg-3 col-md-6">
					<div class="card card-block card-stretch card-height">
						<div class="card-body">
							<div class="d-flex align-items-center justify-content-between">
								<div class="mm-cart-image text-danger">
									<svg class="svg-icon svg-danger" width="50" height="52" id="h-01" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.488 10H14V3.512A9.025 9.025 0 0120.488 9z" /> </svg>
								</div>
								<div class="mm-cart-text">
									<h2 class="font-weight-700">12k</h2>
									<p class="mb-0 text-danger">Total sale</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="card card-block card-stretch card-height">
						<div class="card-body">
							<div class="d-flex align-items-center justify-content-between">
								<div class="mm-cart-image text-success">
									<svg class="svg-icon svg-success mr-4" width="50" height="52" id="h-02" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /> </svg>
								</div>
								<div class="mm-cart-text">
									<h2 class="font-weight-700">250</h2>
									<p class="mb-0 text-success">Orders</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="card card-block card-stretch card-height">
						<div class="card-body">
							<div class="d-flex align-items-center justify-content-between">
								<div class="mm-cart-image text-primary">
									<svg class="svg-icon svg-blue mr-4" id="h-03" width="50" height="52" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /> </svg>
								</div>
								<div class="mm-cart-text">
									<h2 class="font-weight-700">25k</h2>
									<p class="mb-0 text-primary">Customers</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-6">
					<div class="card card-block card-stretch card-height">
						<div class="card-body">
							<div class="d-flex align-items-center justify-content-between">
								<div class="mm-cart-image text-warning">
									<svg class="svg-icon svg-warning mr-4" id="h-04" width="50" height="52" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" /> </svg>
								</div>
								<div class="mm-cart-text">
									<h2 class="font-weight-700">200</h2>
									<p class="mb-0 text-warning">Visitors</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>
		<div class="col-lg-12">
			<div class="card card-block card-stretch card-height">
				<div class="card-header d-flex justify-content-between">
					<div class="header-title">
						<h4 class="card-title">Pegawai Dinas</h4>
					</div>
				</div>
				<div class="card-body">
					<vue-good-table
						:columns="columns"
						:rows="rows"
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'top',
							perPageDropdown: [5, 10, 20],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							nextLabel: 'Selanjutnya',
							prevLabel: 'Sebelumnya',
							rowsPerPageLabel: 'Jumlah baris',
							ofLabel: 'dari',
							pageLabel: 'halaman', // for 'pages' mode
							allLabel: 'Semua',
						}"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'nama'">
								<div class="media align-items-center">
									<a href="javascript:void(0);"><img :src="endpointImg + props.row.path_foto" class="img-fluid avatar-60 rounded-small" alt="image"></a>
									<div class="media-body ml-3">
										<h5>{{ props.row.full_name }}</h5>
										<p class="mb-0 text-primary">{{ props.row.jabatan }}</p>
									</div>
								</div>
							</span>
							<span v-else-if="props.column.field === 'no_spt'">
								<a href="javascript:void(0)" @click="loadSpt(props)">{{ props.row.no_spt }}</a>
							</span>
							<span v-else-if="props.column.field === 'status'">
								<span v-bind:class="props.row.badge">{{ props.row.status }}</span>
							</span>
							<span v-else>
								{{props.formattedRow[props.column.field]}}
							</span>
						</template>
					</vue-good-table>
				</div>
			</div>
		</div>
	</div>
	<!-- Page end  -->
</div>
</template>
<script>

// import ApexChart from '../../components/charts/ApexChart'
import { mapGetters } from 'vuex'
import ApexCharts from 'apexcharts'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { getDataService } from '../../store/modules/crudservices'
import { url_img } from '../../config/config'

export default {
	name:'Dashbord',
	components:{
		VueGoodTable
	},
	data(){
		return{
			endpointImg: url_img,
			columns:[ 
				{
					label: 'Nama Pegawai',
					field: 'nama'
				}, {
					label: 'No. SPT',
					field: 'no_spt'
				}, {
					label: 'Daerah Tujuan',
					field: 'daerah_tujuan'
				}, {
					label: 'Tgl. Berangkat',
					field: 'tgl_berangkat'
				}, {
					label: 'Tgl. Kembali',
					field: 'tgl_kembali'
				}, {
					label: 'Status',
					field: 'status',
					width: '100px',
				}, {
					label: 'Keterangan',
					width: '140px',
					field: 'keterangan',
				}
			],
			rows: [],
			anggaranChart: {
				series: [
				{
					name: 'Anggaran',
					data: []
				}, {
					name: 'Realisasi',
					data: []
				}],
				chart: {
					type: 'bar',
					height: 260
				},
				colors: ['#4788ff', '#37d5f2'],
				plotOptions: {
					bar: {
						horizontal: false,
						// columnWidth: '45%',
						borderRadius: 4,
					},
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					show: false
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent']
				},
				xaxis: {
					categories: [],
					labels:{
						minHeight: 30,
						maxHeight: 30,
					}
				},
				fill: {
					opacity: 1
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return "Rp " + val.toLocaleString()
						}
					}
				}
			}
		}
	},
  computed : {
    ...mapGetters({
      user:'StateUser'
    })
  },
	methods: {
		loadSpt(props) {
			this.$router.push(`/spt/${props.row.spt_id}/sppd`)
		},
		getPegawaiDinas() {
			getDataService('/dashboard-pegawai').then(response => {
				this.rows = response.data
			})
		},
		getAnggaranChart(){
			getDataService('/dashboard-anggaran').then(response => {

				const _this = this
				_this.anggaranChart.series[0].data = response.data.anggaran
				_this.anggaranChart.series[1].data = response.data.realisasi
				_this.anggaranChart.xaxis.categories = response.data.label

				const selector = '#anggaranChart'
				const chart = new ApexCharts(document.querySelector(selector), _this.anggaranChart)

				setTimeout(() => {
					chart.render()
					if (_this.isLive) {
						setInterval(function () {
							_this.getNewSeries(_this.lastDate, {
								min: 10,
								max: 90
							})
							chart.updateSeries([{
								data: _this.data
							}])
						}, 1000)
					}
				}, 500)
			})
		},
		handleChart() {

		}
	},
	mounted() {
		this.getAnggaranChart()
		this.getPegawaiDinas()
	}

}
</script>