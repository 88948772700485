var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mb-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between welcome-content"},[_c('div',{staticClass:"navbar-breadcrumb"},[_c('h4',{staticClass:"mb-0 font-weight-700"},[_vm._v("Selamat Datang, "+_vm._s(_vm.user.fullName))])])])]),_vm._m(0),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'top',
							perPageDropdown: [5, 10, 20],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							nextLabel: 'Selanjutnya',
							prevLabel: 'Sebelumnya',
							rowsPerPageLabel: 'Jumlah baris',
							ofLabel: 'dari',
							pageLabel: 'halaman', // for 'pages' mode
							allLabel: 'Semua',
						}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'nama')?_c('span',[_c('div',{staticClass:"media align-items-center"},[_c('a',{attrs:{"href":"javascript:void(0);"}},[_c('img',{staticClass:"img-fluid avatar-60 rounded-small",attrs:{"src":_vm.endpointImg + props.row.path_foto,"alt":"image"}})]),_c('div',{staticClass:"media-body ml-3"},[_c('h5',[_vm._v(_vm._s(props.row.full_name))]),_c('p',{staticClass:"mb-0 text-primary"},[_vm._v(_vm._s(props.row.jabatan))])])])]):(props.column.field === 'no_spt')?_c('span',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.loadSpt(props)}}},[_vm._v(_vm._s(props.row.no_spt))])]):(props.column.field === 'status')?_c('span',[_c('span',{class:props.row.badge},[_vm._v(_vm._s(props.row.status))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card card-block card-stretch card-height"},[_c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Anggaran")])])]),_c('div',{staticClass:"card-body align-items-center"},[_c('div',{attrs:{"id":"anggaranChart"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header d-flex justify-content-between"},[_c('div',{staticClass:"header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Pegawai Dinas")])])])
}]

export { render, staticRenderFns }